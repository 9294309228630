<template>
  <div class="checkpoint">
    <div class="checkMain">
      <div class="topInfo">
        <div class="player">
          <span class="label">玩家</span>
          <div class="value">{{ player }}</div>
        </div>
        <div class="card">
          <span class="label">拥有卡牌</span>
          <div class="value">{{ currentCard.id }}</div>
        </div>
      </div>
      <div
        class="currentcard"
        v-if="current"
        :style="{
          'background-image': 'url(' + cardList[id - 1].activeImg + ')',
        }"
      >
        <img class="succeed" src="@/assets/image/other/succeed.png" alt="" />
        <img class="star" src="@/assets/image/other/star1.png" alt="" />
      </div>
      <p class="tips" v-if="current">
        恭喜你，完成第{{ currentCard.id }}关挑战，获得卡牌+1
      </p>
      <div class="startBtn" v-if="current" @click="continues">好的</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      player: '',
      cardList: [
        {
          id: 1,
          name: '卡片找同伴',
          activeImg: require('../../assets/image/other/ka3-1.png'),
        },
        {
          id: 2,
          name: '脸谱大作战',
          activeImg: require('../../assets/image/other/ka1-1.png'),
        },
        {
          id: 3,
          name: '千万别按X',
          activeImg: require('../../assets/image/other/ka2-1.png'),
        },
        {
          id: 4,
          name: '图片躲猫猫',
          activeImg: require('../../assets/image/other/ka4-1.png'),
        },
        {
          id: 5,
          name: '镜子中的我',
          activeImg: require('../../assets/image/other/ka5-1.png'),
        },
      ],
      id: '',
      current: '',
      currentCard: {},
    };
  },
  methods: {
    continues() {
      this.$util.fullScreen();
      this.$router.replace('/checkpoint');
    },
  },
  created() {
    this.player = this.$util.getStorage('name');
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
    if (this.$route.query.current) {
      this.current = this.$route.query.current;
    }
  },
  mounted() {
    if (this.id) {
      this.currentCard = this.cardList.filter((item) => {
        return item.id == this.id;
      })[0];
    }
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .checkpoint {
    text-align: center;
    width: 100%;
    min-width: 1000px;
    height: 100vh;
    background-image: url('../../assets/image/other/bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-family: 'SYHeiBold';
    .checkMain {
      width: 1050px;
      height: 650px;
      position: relative;
      margin: 0 auto;
      top: calc((100% - 650px) / 2);
      .topInfo {
        text-align: right;
        .player {
          vertical-align: top;
          display: inline-block;
          margin-right: 30px;
          .value {
            width: 102px;
            height: 46px;
            line-height: 46px;
            padding: 0 10px;
            background-image: url('../../assets/image/other/playerBg.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .card {
          vertical-align: top;
          display: inline-block;
          .value {
            width: 46px;
            height: 46px;
            line-height: 46px;
            background-image: url('../../assets/image/other/cardNumBg.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
        .label {
          vertical-align: top;
          font-size: 20px;
          line-height: 46px;
          display: inline-block;
          color: #259552;
          text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
        }
        .value {
          display: inline-block;
          color: #7a3398;
          font-size: 20px;
          text-align: center;
        }
      }
      .currentcard {
        width: 192px;
        height: 252px;
        margin: 10px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        // background: #cecece;
        // border: 6px solid #ffffff;
        // border-radius: 20px;
        display: inline-block;
        position: relative;
        .succeed {
          width: 120px;
          height: 96px;
          position: absolute;
          top: -30px;
          left: -30px;
        }
        .imgBox {
          width: 168px;
          height: 150px;
          background: #ffffff;
          border-radius: 16px;
          margin: 6px auto;
        }
        .name {
          color: #a1a1a1;
          font-size: 24px;
          line-height: 42px;
          text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
        }
        .star {
          width: 144px;
          height: 46px;
          position: absolute;
          left: 50%;
          bottom: -30px;
          margin-left: -72px;
        }
      }
      .tips {
        margin-top: 86px;
        font-size: 24px;
        color: #259552;
        text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
      }
      .startBtn {
        width: 216px;
        height: 84px;
        line-height: 65px;
        text-align: center;
        display: inline-block;
        background-image: url('../../assets/image/other/btn.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        font-size: 30px;
        color: #ffffff;
        margin-top: 34px;
        cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .checkpoint {
    text-align: center;
    width: 100%;
    min-width: 1300px;
    height: 100vh;
    background-image: url('../../assets/image/other/bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-family: 'SYHeiBold';
    .checkMain {
      width: 1365px;
      height: 845px;
      position: relative;
      margin: 0 auto;
      top: calc((100% - 845px) / 2);
      .topInfo {
        text-align: right;
        .player {
          vertical-align: top;
          display: inline-block;
          margin-right: 39px;
          .value {
            width: 143px;
            height: 60px;
            line-height: 60px;
            background-image: url('../../assets/image/other/playerBg.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding:0 13px;
          }
        }
        .card {
          vertical-align: top;
          display: inline-block;
          .value {
            width: 60px;
            height: 60px;
            line-height: 60px;
            background-image: url('../../assets/image/other/cardNumBg.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
        .label {
          vertical-align: top;
          font-size: 26px;
          line-height: 60px;
          display: inline-block;
          color: #259552;
          text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
        }
        .value {
          display: inline-block;
          color: #7a3398;
          font-size: 26px;
          text-align: center;
        }
      }
      .currentcard {
        width: 250px;
        height: 328px;
        margin: 13px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        // background: #cecece;
        // border: 6px solid #ffffff;
        // border-radius: 20px;
        display: inline-block;
        position: relative;
        .succeed {
          width: 156px;
          height: 129px;
          position: absolute;
          top: -39px;
          left: -39px;
        }
        .imgBox {
          width: 218px;
          height: 195px;
          background: #ffffff;
          border-radius: 20px;
          margin: 8px auto;
        }
        .name {
          color: #a1a1a1;
          font-size: 31px;
          line-height: 55px;
          text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
        }
        .star {
          width: 187px;
          height: 60px;
          position: absolute;
          left: 50%;
          bottom: -39px;
          margin-left: -94px;
        }
      }
      .tips {
        margin-top: 112px;
        font-size: 31px;
        color: #259552;
        text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
      }
      .startBtn {
        width: 281px;
        height: 109px;
        line-height: 84px;
        text-align: center;
        display: inline-block;
        background-image: url('../../assets/image/other/btn.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        font-size: 39px;
        color: #ffffff;
        margin-top: 44px;
        cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
      }
    }
  }
}
</style>
